/**
 * Member types defined in `memberModel`
 *
 * @see
 * https://github.com/healthcarecom/shared-assets/blob/master/src/js/sem/storage-manager/defaultMember.js
 */
export default {
  /* 0 */ PRIMARY: 'Primary',
  /* 1 */ SPOUSE: 'Spouse',
  /* 2 */ CHILD: 'Child',
};
