import dobRule from '@hc-frontend/shared-assets/src/js/sem/validator/rules/dob';
import date from '@hc-frontend/shared-assets/src/js/sem/validator/rules/date';
import TYPE_PERSON from '../applicant/types';

const errorMessage = {
  [TYPE_PERSON.PRIMARY]: {
    min: 'You must be 18 years old to apply for health insurance.',
    max: 'Your age must be under 99 years old.',
  },
  [TYPE_PERSON.SPOUSE]: {
    min: 'Spouse must be 18 years old or older.',
    max: 'Spouse age must be under 99 years old.',
  },
  [TYPE_PERSON.CHILD]: 'Child must be under 26 years of age',
};

/**
 * @param {Object} member member
 * @returns {Boolean}
 */
function validDob(dob, personType = TYPE_PERSON.PRIMARY) {
  const isDateValid = date({
    value: dob,
  });
  if (!isDateValid.isValid) {
    return {
      isValid: false,
      errorMessage:
        'This field is required. Please fill it with valid information.',
    };
  }

  const message = {};
  const parameters = {
    'dob-min': 18,
    'dob-max': 99,
  };

  if (personType === TYPE_PERSON.CHILD) {
    message.content = errorMessage[personType];
    parameters['dob-content'] = errorMessage[personType];
    parameters['dob-min'] = 0;
    parameters['dob-max'] = 26;
  } else {
    message.content = errorMessage[personType].min;
    parameters['dob-min-content'] = errorMessage[personType].min;
    parameters['dob-max-content'] = errorMessage[personType].max;
  }

  const isDobValid = dobRule({
    value: dob,
    message,
    parameters,
  });

  const response = {
    isValid: isDobValid.isValid,
    errorMessage: isDobValid.message ? isDobValid.message.content : '',
  };

  return response;
}

export default validDob;
