import usAreaCodes from '../USA/areaCodes';

const US_PHONE = /1?\W*([2-9][0-8][0-9])\W*([0-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;

function phone(value) {
  const areaCode = +value.replace(/\D/g, '').substring(0, 3);

  return usAreaCodes.indexOf(areaCode) >= 0 && US_PHONE.test(value);
}

export default phone;
