const digits = /\d+/;
const words = /[a-z\u0027\u00E0-\u00FC]{2,}/gi;
const space = /\s(?=.+)/;

function address(value) {
  const cleanValue = value.trim();
  const wordsFound = cleanValue.match(words);

  return !!(
    digits.test(cleanValue) &&
    space.test(cleanValue) &&
    wordsFound &&
    wordsFound.length
  );
}

export default address;
